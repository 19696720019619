import React, { useEffect } from "react"
import { useSelector } from 'react-redux'
import { navigate } from "gatsby"

import Spinner from '../components/Spinner'

import DefaultLayout from '../layouts/DefaultLayout'

const Home = props => {
  const user = useSelector(state => state.auth)

  useEffect(() => {
    if (!user.loggedIn) {
      navigate('/login');
    }else{
      navigate('/app/dashboard');
    }
  }, []);
  
  return (
    <DefaultLayout title="Index redirección">
      <Spinner />
    </DefaultLayout>
  );
}

export default Home;